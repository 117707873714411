<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <!-- <b>{{ formTitle }}</b> -->
      <!-- <b>服务订单报告上传</b> -->
           {{$t('服务管理.服务订单管理.服务订单报告上传')}}
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="用户id,外键{zb_user.id}" prop="userId">-->
      <!--        <a-input v-model="form.userId" placeholder="请输入用户id,外键{zb_user.id}" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="后巷屋id,外键{zb_laneway_houses.id}" prop="lanewayHousesId">-->
      <!--        <a-input v-model="form.lanewayHousesId" placeholder="请输入后巷屋id,外键{zb_laneway_houses.id}" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="后巷屋封面" prop="cover">-->
      <!--        <a-input v-model="form.cover" placeholder="请输入后巷屋封面" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="后巷屋标题" prop="title">-->
      <!--        <a-input v-model="form.title" placeholder="请输入后巷屋标题" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="后巷屋定金" prop="deposit">-->
      <!--        <a-input v-model="form.deposit" placeholder="请输入后巷屋定金" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="后巷屋预估价格" prop="estimatedPrice">-->
      <!--        <a-input v-model="form.estimatedPrice" placeholder="请输入后巷屋预估价格" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item :label="$t('服务管理.服务订单管理.上传报告')" prop="report">
        <!-- <a-input v-model="form.report" placeholder="请输入内容" type="textarea" allow-clear /> -->
        <file-upload
          type="file"
          :count="10"
          @input="handleInput"
          @beforeUpload="beforeUpload"
          :defaultList="fileList"
        />
      </a-form-model-item>
      <!--      <a-form-model-item label="订单状态" prop="status">-->
      <!--        <a-select placeholder="请选择订单状态" option-filter-prop="children" style="width: 100%" v-model="form.status">-->
      <!--          <a-select-option value="0">待付款</a-select-option>-->
      <!--          <a-select-option value="1">已下单 </a-select-option>-->
      <!--          <a-select-option value="2">制作方案</a-select-option>-->
      <!--          <a-select-option value="3">施工</a-select-option>-->
      <!--          <a-select-option value="4">已完成</a-select-option>-->
      <!--          <a-select-option value="5">已关闭</a-select-option>-->
      <!--        </a-select>-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="进度百分比" prop="progressPercentage">-->
      <!--        <a-input v-model="form.progressPercentage" placeholder="请输入进度百分比" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="定金已抵扣金额" prop="deductionMoney">-->
      <!--        <a-input v-model="form.deductionMoney" placeholder="请输入定金已抵扣金额" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="定金支付状态,0-待支付,1-已支付" prop="payStatus">-->
      <!--        <a-select placeholder="请选择支付状态" option-filter-prop="children" style="width: 100%" v-model="form.payStatus">-->
      <!--          <a-select-option value="0">待支付</a-select-option>-->
      <!--          <a-select-option value="1">已支付 </a-select-option>-->
      <!--        </a-select>-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="定金支付时间" prop="payTime">-->
      <!--        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="定金支付过期时间" prop="expirationTime">-->
      <!--        <a-date-picker style="width: 100%" v-model="form.expirationTime" format="YYYY-MM-DD HH:mm:ss" allow-clear />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="累计支付金额" prop="allMoney">-->
      <!--        <a-input v-model="form.allMoney" placeholder="请输入累计支付金额" />-->
      <!--      </a-form-model-item>-->
      <!--      <a-form-model-item label="备注" prop="remark">-->
      <!--        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />-->
      <!--      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> {{$t('通用.保存')}} </a-button>
          <a-button type="dashed" @click="cancel"> {{$t('通用.取消')}} </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLanewayHouses, addLanewayHouses, updateLanewayHouses } from '@/api/order/lanewayHouses'
import { getMulFile, postMulFile , postFile } from '@/api/file/file'
export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        lanewayHousesId: null,

        cover: null,

        title: null,

        deposit: null,

        estimatedPrice: null,

        report: null,

        status: 0,

        progressPercentage: null,

        deductionMoney: null,

        payStatus: 0,

        payTime: null,

        expirationTime: null,

        allMoney: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [{ required: true, message: '用户id,外键{zb_user.id}不能为空', trigger: 'blur' }],
        lanewayHousesId: [{ required: true, message: '后巷屋id,外键{zb_laneway_houses.id}不能为空', trigger: 'blur' }],
        cover: [{ required: true, message: '后巷屋封面不能为空', trigger: 'blur' }],
        title: [{ required: true, message: '后巷屋标题不能为空', trigger: 'blur' }],
        deposit: [{ required: true, message: '后巷屋定金不能为空', trigger: 'blur' }],
        estimatedPrice: [{ required: true, message: '后巷屋预估价格不能为空', trigger: 'blur' }],
        report: [{ required: true, message: '报告,外键{zb_file.id},多个使用逗号隔开不能为空', trigger: 'blur' }],
        status: [
          {
            required: true,
            message: '订单状态,0-待付款,1-已下单,2-制作方案,3-施工,4-已完成,5-已关闭不能为空',
            trigger: 'blur',
          },
        ],
        progressPercentage: [{ required: true, message: '进度百分比不能为空', trigger: 'blur' }],
        deductionMoney: [{ required: true, message: '定金已抵扣金额不能为空', trigger: 'blur' }],
        payStatus: [{ required: true, message: '定金支付状态,0-待支付,1-已支付不能为空', trigger: 'blur' }],
        allMoney: [{ required: true, message: '累计支付金额不能为空', trigger: 'blur' }],
      },
      fileObject: {
        fileAddParamList: [],
        id: null,
      },
      obj: {
        extend: '',
        id: '',
        name: '',
        url: '',
      },
      fileList: [],
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        lanewayHousesId: null,
        cover: null,
        title: null,
        deposit: null,
        estimatedPrice: null,
        report: null,
        status: 0,
        progressPercentage: null,
        deductionMoney: null,
        payStatus: 0,
        payTime: null,
        expirationTime: null,
        allMoney: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLanewayHouses({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })

      getMulFile([row.orderLanewayHousesId]).then((res) => {
        // console.log('报告', res.data)
        this.fileList = []
        res.data.forEach((item) => {
          this.fileList.push({
            uid: item.id,
            name: item.name,
            status: 'done',
            url: item.url,
          })
        })
      })
      this.fileObject.id = row.id
    },
    handleUpload(row, ids) {
      this.open = true
      getMulFile(row.fileIds.split(',')).then((res) => {
        this.fileList = []
        res.data.forEach((item) => {
          this.fileList.push({
            uid: item.id,
            name: item.name,
            status: 'done',
            url: item.url,
          })
        })
      })
      this.fileObject.id = row.id
    },
    /** 提交按钮 */
    submitForm: function () {
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     this.submitLoading = true
      //     if (this.form.id !== undefined && this.form.id !== null) {
      //       updateLanewayHouses(this.form)
      //         .then((response) => {
      //           this.$message.success('修改成功', 3)
      //           this.open = false
      //           this.$emit('ok')
      //         })
      //         .finally(() => {
      //           this.submitLoading = false
      //         })
      //     } else {
      //       addLanewayHouses(this.form)
      //         .then((response) => {
      //           this.$message.success('新增成功', 3)
      //           this.open = false
      //           this.$emit('ok')
      //         })
      //         .finally(() => {
      //           this.submitLoading = false
      //         })
      //     }
      //   } else {
      //     return false
      //   }
      // })

      postFile(this.fileObject).then((res) => {
        // console.log('文件提交成功', res)
        if (res.message == '成功.') {
          this.open = false
          this.$message.success('提交成功!')
        }
      })
    },
    handleInput(file) {
      // console.log(file)

      // this.obj.url = file[0].url
      // this.fileObject.fileAddParamList.push(this.obj)
      this.fileObject.fileAddParamList = []
      file.forEach((item) => {
        this.obj.url = item.url
        this.fileObject.fileAddParamList.push(this.obj)
      })

      // console.log('1' , this.fileObject)
    },
    beforeUpload(file) {
      //  console.log(file)

      this.obj.extend = file.name.split('.')[1].toString()
      this.obj.name = file.name.split('.')[0].toString()
    },
  },
}
</script>
